import React from 'react';
import { NavLink } from 'react-router-dom';
import abstractAndIndexingPageData from '../content/abstract-and-indexing-page.json';


const { text, services } = abstractAndIndexingPageData;


const AbstractAndIndexingPageContent = () => (
    <div>
        <section className="main-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="main-text">
                            <h2>Abstract & Indexing</h2>
                            <div className="bt-option">
                                <NavLink to="/home">Home</NavLink>
                                <span>Abstract & Indexing</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="aims-and-scopes-section spad">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-title">
                        <p className="f-para">{text}</p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="aims-and-scopes-text">
                    <ul>   
                    {services.map((service, index) => (
                        <li key={index}><span className="tab"></span><span className="fa fa-circle"></span>{service}</li>
                    ))}
                    </ul>
                </div>
            </div>
        </div>
    </section>
    </div>
);

export default AbstractAndIndexingPageContent;