import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import EditorialPageContent from '../components/EditorialPageContent.js';


const EditorialPage = () => (
    <div>
        <Header />
        <EditorialPageContent />
        <Footer />
    </div>
);


export default EditorialPage;