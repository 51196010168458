import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import AimsAndScopesPageContent from '../components/AimsAndScopesPageContent.js';


const AimsAndScopesPage = () => (
    <div>
        <Header />
        <AimsAndScopesPageContent />
        <Footer />
    </div>
);


export default AimsAndScopesPage;
