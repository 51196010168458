import React from 'react';

const Footer = () => (
    <footer className="footer-section">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="footer-text">
                        <div className="copyright-text">
                            <p>
                                Copyright &copy;{new Date().getFullYear()} All rights reserved
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
);

export default Footer;
