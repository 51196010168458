import React from "react";
import { NavLink } from 'react-router-dom';
import aimsAndScopesData from '../content/aims-and-scopes-page.json';


const { text, topics, sections } = aimsAndScopesData;


const AimsAndScopesPageContent = () => (
    <div>
        <section className="main-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="main-text">
                            <h2>About Us</h2>
                            <div className="bt-option">
                                <NavLink to="/">Home</NavLink>
                                <span className="breadcrumb-separator"></span>
                                <span>Aims & Scopes</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="aims-and-scopes-section spad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title">
                            {text.map((paragraph, index) => (
                                <p key={index} className="f-para">{paragraph}</p>
                            ))}
                            <ul>
                                {topics.map((topic, index) => (
                                    <li key={index}><span className="icon_check"></span> {topic}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {sections[0].map((section, index) => (
            <section key={index} className="aims-and-scopes-section spad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title">
                                <h2>{section.header}</h2>
                                {section.text.map((para, paraIndex) => (
                                    <p key={paraIndex} className="f-para">{para}</p>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        ))}
    </div>
);

export default AimsAndScopesPageContent;