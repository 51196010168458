import React from 'react';
import { NavLink } from 'react-router-dom';
import submitPageData from '../content/submit-page.json';


// Utility function to make links clickable
const convertTextToLinks = (text) => {
    const pattern = /(submission@ejaam.org|Sample_LaTeX.zip)/ig;

    return text.split(pattern).map((part, index) => {
        if (part.match(/submission@ejaam.org/ig)) {
            return <a key={index} href={`mailto:${part}`} className="mail">{part}</a>;
        } else if (part.match(/Sample_LaTeX.zip/ig)) {
            return <a key={index} href={`${process.env.PUBLIC_URL}/files/Sample_LaTeX.zip`} download="Sample_LaTeX.zip">LaTeX</a>;
        } else {
            return part;
        }
    });
};


const SubmitPageContent = () => {
    const { "Submission of manuscripts": submissionInfo, "Open Access License": openAccessLicense, "Open Access Statement": openAccessStatement } = submitPageData;

    return (
        <div>
            <section className="main-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="main-text">
                                <h2>Submission</h2>
                                <div className="bt-option">
                                    <NavLink to="/">Home</NavLink>
                                    <span>Submit</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="submit-section spad">
                <div className="container">
                    <div className="section-title">
                        <h2>Submission of manuscripts</h2>
                        {submissionInfo.text.map((para, index) => (
                            <p className="f-para" key={index}>{convertTextToLinks(para)}</p>
                        ))}
                        <ol>
                            {submissionInfo.instructions.map((instruction, index) => (
                                <li key={index}><p className="f-para">{convertTextToLinks(instruction)}</p></li>
                            ))}
                        </ol>
                        {Object.entries(submissionInfo.types).map(([type, content], index) => (
                            <div key={index}>
                                <p className="f-para">{type}:</p>
                                <p className="f-para">{content}</p>
                            </div>
                        ))}
                    </div>

                    {/* Open Access License */}
                    <div className="section-title">
                        <h2>Open Access License</h2>
                        {openAccessLicense.map((paragraph, index) => (
                            <p className="f-para" key={index}>{paragraph}</p>
                        ))}
                    </div>

                    {/* Open Access Statement */}
                    <div className="section-title">
                        <h2>Open Access Statement</h2>
                        {openAccessStatement.map((paragraph, index) => (
                            <p className="f-para" key={index}>{paragraph}</p>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubmitPageContent;