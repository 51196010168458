import React from 'react';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import ViewArticlePageContent from '../components/ViewArticlePageContent.js';


const ViewArticlePage = () => (
    <div>
        <Header />
        <ViewArticlePageContent />
        <Footer />
    </div>
);


export default ViewArticlePage;
