import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import HomePageContent from '../components/HomePageContent.js';


const HomePage = () => (
  <div>
    <Header />
    <HomePageContent />
    <Footer />
  </div>
);


export default HomePage;
