import React from "react";
import homePageData from "../content/home-page.json";
import coverImage from "../images/cover-image-original.jpg";
import ccImage from "../images/cc.xlarge.png";
import byImage from "../images/by.xlarge.png";


// Utility function to make links clickable
const makeLinksClickable = (text) => {
  const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
  return text.replace(urlRegex, (url) => `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`);
};

const RenderTextWithLinks = (text) => {
  const htmlText = makeLinksClickable(text);
  return <span dangerouslySetInnerHTML={{ __html: htmlText }} />;
};

const { detail, about } = homePageData;
const ccbyLink = "https://creativecommons.org/licenses/by/4.0/deed.en";


const HomePageContent = () => (
  <section className="home-about-section spad">
    <div className="container">
      <div className="row">
        <div className="col-lg-4">
          <div className="ha-pic-and-info">
            <div className="ha-pic">
              <img src={coverImage} alt="" />
            </div>
            <div className="additional-info">
              <a href={ccbyLink} target="_blank" rel="noopener noreferrer" className="cc-image">
                <img src={ccImage} alt="CC BY 4.0" />
              </a>
              <a href={ccbyLink} target="_blank" rel="noopener noreferrer" className="by-image">
                <img src={byImage} alt="CC BY 4.0" />
              </a>
              <p>
                {Object.entries(detail).map(([label, value]) => (
                  <React.Fragment key={label}>
                    {label !== "other" ? (
                      <span>
                        <span className="info-label">{label}</span>: {value}<br />
                      </span>
                    ) : (
                      Object.entries(value).map(([key, val]) => (
                        <span key={key}>
                          {key}: {val}<br />
                        </span>
                      ))
                    )}
                  </React.Fragment>
                ))}
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-8">
          <div className="ha-text">
            <h2>{about.header}</h2>
            {about.text.map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
            {about.more.map((paragraph, index) => (
              <p className="additional-text" key={index}>{RenderTextWithLinks(paragraph)}</p>
            ))}
            <div className="additional-info">
              <p>
                <span className="info-label">{about.contact.header}</span>:<br />
                {about.contact.name}<br />
                {about.contact.address.map((line, index) => (
                  <React.Fragment key={index}>
                    {line}<br />
                  </React.Fragment>
                ))}
                <a href={`mailto:${about.contact.link}`}>{about.contact.link}</a>
              </p>
            </div>
            <a href="./aims-and-scopes.html" className="ha-btn">Discover Now</a>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default HomePageContent;
