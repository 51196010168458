import React from 'react';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import SubmitPageContent from '../components/SubmitPageContent.js';


const SubmitPage = () => (
  <div>
    <Header />
    <SubmitPageContent />
    <Footer />
  </div>
);


export default SubmitPage;
