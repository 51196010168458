import React, { useState, useEffect } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';
import volumePageData from '../content/volumes-page.json';


const VolumePageContent = () => {
    const { year } = useParams();
    const [volume, setVolume] = useState([]);

    useEffect(() => {
        const yearVolume = volumePageData[year] || [];
        setVolume(yearVolume);
    }, [year]);

    return (
        <div>
            <section className="main-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="main-text">
                                <h2>{year}</h2>
                                <div className="bt-option">
                                    <NavLink to="/">Home</NavLink>
                                    <span>Volumes</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="volume-section spad">
                <div className="container">
                    {volume.map((article, index) => (
                        <div key={index} className="section-title">
                            <h4><Latex>{article.title}</Latex></h4>
                            <div className="button-group">
                                <NavLink to={`/volumes/${year}/article/${article.documentName}`} className='view-btn'>View</NavLink>
                                <a href={article.path} download={article.documentName} target='_blank'>PDF</a>
                            </div>
                            <p>{article.authors.map(author => author.name).join(', ')}</p>
                            <p>Published Online: {article.publishedOnline}</p>
                            <p>Page range: {article.pageRange}</p>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
};

export default VolumePageContent;
