import React from "react";
import { NavLink } from 'react-router-dom';
import editorialPageData from '../content/editorial-page.json';


const EditorialPageContent = () => (
    <div>
        <section className="main-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="main-text">
                            <h2>Editorial Board</h2>
                            <div className="bt-option">
                                <NavLink to="/">Home</NavLink>
                                <span>Editorial</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="editorial-section spad">
            <div className="container">
                {Object.entries(editorialPageData).map(([role, members]) => (
                    <div key={role}>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title">
                                    <h3>{role}</h3>
                                </div>
                            </div>
                        </div>
                        {members.map((member, index) => (
                            <div className="col-sm-12" key={index}>
                                <div className="editorial-item">
                                    <div className="row">
                                        <div className="col-sm-10">
                                            <div className="si-text">
                                                <div className="si-title">
                                                    <h4>{member.name}</h4>
                                                    <span>{member.university}</span>
                                                </div>
                                                <p>{member.fields}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </section>
    </div>
);

export default EditorialPageContent;