import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import AbstractAndIndexingPageContent from '../components/AbstractAndIndexingPageContent.js';


const AbstractAndIndexingPage = () => (
    <div>
        <Header />
        <AbstractAndIndexingPageContent />
        <Footer />
    </div>
);


export default AbstractAndIndexingPage;
