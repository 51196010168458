import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import volumeData from '../content/volumes-page.json';

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(true);
    const [activeDropdown, setActiveDropdown] = useState(null);

    const years = Object.keys(volumeData).sort((a, b) => b - a); // Sorting years in descending order

    useEffect( () => {
        if (window.innerWidth < 992) {
            setMenuOpen(false);
        }
    }, []);
    
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };


    const handleDropdownToggle = (index) => {
        setActiveDropdown(activeDropdown === index ? null : index);
    };

    return (
        <header className="header-section">
            <div className="container">
                <div className="header-menu">
                    <div className="header-content">
                        <div className="logo">
                            <NavLink to="/home">
                                <p>e-JAAM</p>
                                <span className="e-isnn">e-ISSN 2544-9990</span>
                            </NavLink>
                        </div>
                        <button className="mobile-menu-toggle" onClick={toggleMenu}>
                            Menu ☰
                        </button>
                    </div>
                    {menuOpen ? 
                        <div className={`nav-menu`}>
                            <nav className={`mainmenu`}>
                                <ul>
                                    <li>
                                        <NavLink to="/" className={({ isActive }) => (isActive ? "active" : "")}>
                                            Home
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/aims-and-scopes" className={({ isActive }) => (isActive ? "active" : "")}>
                                            Aims & Scopes
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/editorial" className={({ isActive }) => (isActive ? "active" : "")}>
                                            Editorial
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/abstract-and-indexing" className={({ isActive }) => (isActive ? "active" : "")}>
                                            Abstract & Indexing
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="#" className={({ isActive }) => (isActive ? "active" : "")} onClick={() => handleDropdownToggle(0)}>
                                            Volumes
                                        </NavLink>
                                        {activeDropdown === 0 && (
                                            <ul className="dropdown">
                                                {years.map(year => (
                                                    <li key={year}>
                                                        <NavLink to={`/volumes/${year}`}>{year}</NavLink>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </li>
                                    <li>
                                        <NavLink to="/submit" className={({ isActive }) => (isActive ? "active" : "")}>
                                            Submit
                                        </NavLink>
                                    </li>
                                </ul>
                            </nav>
                        </div> : 
                        <div/>
                    }
                </div>
            </div>
        </header>
    );
};

export default Header;