import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import HomePage from './pages/HomePage.js';
import AimsAndScopesPage from './pages/AimsAndScopesPage.js';
import EditorialPage from './pages/EditorialPage.js';
import AbstractAndIndexingPage from './pages/AbstractAndIndexingPage.js';
import ViewArticlePage from './pages/ViewArticlePage.js';
import VolumePage from './pages/VolumePage.js';
import SubmitPage from './pages/SubmitPage.js';
import './styles/style.css';
import './styles/aims-and-scopes.css';
import './styles/article.css';
import './styles/editorial.css';
import './styles/footer.css';
import './styles/header.css';
import './styles/home.css';
import './styles/loader.css';
import './styles/submit.css';
import './styles/volume.css';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <div id="preloder">
      <div className="loader"></div>
    </div>;
  }

  return (
    <Router>
      <Routes>
        <Route path="/home" element={<HomePage />} />
        <Route path="/aims-and-scopes" element={<AimsAndScopesPage />} />
        <Route path="/editorial" element={<EditorialPage />} />
        <Route path="/abstract-and-indexing" element={<AbstractAndIndexingPage />} />
        <Route path="/volumes/:year/article/:id" element={<ViewArticlePage />} />
        <Route path="/volumes/:year" element={<VolumePage />} />
        <Route path="/submit" element={<SubmitPage />} />
        <Route path="*" element={<Navigate replace to="/home" />} />
      </Routes>
    </Router>
  );
}


export default App;
