import React from 'react';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import VolumePageContent from '../components/VolumePageContent.js';


const VolumePage = () => (
  <div>
    <Header />
    <VolumePageContent />
    <Footer />
  </div>
);


export default VolumePage;
